<!--
ActuPage
Utilise le default-layout
-->
<template>
  <default-layout class="actupage">
      <h1>Ceci est une mise à jour !!!!!!</h1>
      <p>Ceci est la page d'actualités. Elles sont toutes merveilleuses.</p>
      <p>Regardez ma belle actualité ! N'est-elle pas formidable ?</p>
      <p>Voici un motherfucking lorem ipsum sa mère.</p>
      <p>On en rajoute encore.</p>
      <h1>HA ! Ceci est vachement gros, hein ?!</h1>
      <p>Mais cela ne sert à rien de crier.</p>
      <p>Tout vient à point à qui sait faire cuire son steak.</p>
      <p>Sur une échelle de 24 à 32, de 24 à 29 c'est une page d'actualités, de 30 à 31 il n'y a que de la merde dessus, et seulement à 32 le texte est là pour débugger le scroll de la page.</p>
  </default-layout>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>

import DefaultLayout from 'components/layouts/default-layout.vue';

export default {
  components: {
    'default-layout': DefaultLayout
  },

  data () { return {
    
  };}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  //.actupage{}
</style>