<!--
HeaderMain
Mise en place générique du header.
Pour afficher un header, utilisez les autres components ./hearder-*.
-->
<template>
  <header class="header-main">
    <nav class="nav-main">
      <ul class="nav-left">
        <slot name="navlinks">&lt;&lt;Menu vide&gt;&gt;</slot>
      </ul>
      <div class="spacer"></div>
      <ul class="nav-right">
        <header-timer/>
        <li class="separator"></li>
        <li class="dark-item"><a href="#header-drop-down" class="header-arrow"><icon name="arrow-down" size=".72rem"/></a></li>
        <li class="dark-item"><a href="#search"><icon name="search"/></a></li>
      </ul>
    </nav>
  </header>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import Icon from 'components/widgets/icon.vue';

import HeaderTimer from './header-timer.vue';

export default {
  components: {
    Icon,
    HeaderTimer
  },
  data () { return {};}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  @import './links.scss';
  .default-layout{
      padding-top: $header-height;
  }

  .header-main{
    position: fixed; top: 0; left: 0;
    @include wrapper-row; height: $header-height;
    color: $color-stal-lightorange;
    @include filter-light-shadow;

    .nav-main{ @include flex-row; } // <ul> wrapper, so it's him the flex container, not .header-main
    
    ul{ // links lists (not spacer)
      flex: 0 0 auto; // auto size but not extensible
      margin: 0; padding: 0; list-style-type: none; // list style reset
      @include flex-row; // it is <li> wrapper, so it's a flex-container row

      &>li{ // list items. it's single <a> wrappers so not a flex container
        flex: 0 0 auto; // auto size but not extensible
        @include wrapper-row-item;
      }

      a{ // links. Content is centered with flexbox
        @include flex-row-center;
        @include link-reset;
        @include typo-crimson-regular;
        padding: 0 $header-items-padding;
        font-size: 1.2rem;
      }
    }

    .spacer{
      flex: 1 0 0; // no width but extensible
      background: $color-stal-red;
    }

    .nav-right{
      &>li a{ @include header-link-no-arrow; }
      .dark-item a{ background: $color-stal-darkred; }
    }

    .header-arrow{
      // size : 0.72rem
      padding: 0 ((1rem - 0.72rem) / 2) + 0.5rem;
    }

    .separator{
      background: $color-stal-red;
      position: relative;
      border: solid transparent;
      border-width: $header-height $header-height*cos(75deg) 0 0;
      border-right-color: $color-stal-darkred;
    }
  }
</style>