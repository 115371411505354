<!--
StalDatetime
Component affichant un temps au format stalnom, ou un décompte.
Props :
- date : Une date au format stalnom. Par défaut l'instant actuel. StalDateTime affichera l'équivalent au format stalnom.
- remaining : true/false (défaut false). Au lieu d'afficher la date, cela affiche le temps restant au format stalnom.
- format : "datetime", "date", "time", "ago". Mode d'affichage. Jouer et heure, jour seul, heure seule, nb d'années/kyrielles/jours/heures avant maintenant.
- run : true/false (défaut false). Mise à jour du timer en temps réel ou non.
-->
<template>
  <div class="stal-datetime">
    <div class="stal-date">
      <span class="stal-date-year">{{ _date.y }}</span>
      <span class="tilde">&nbsp;~&nbsp;</span>
      <span class="stal-date-kyr">{{ _date.k }}</span>
      <span class="tilde">&nbsp;~&nbsp;</span>
      <span class="stal-date-day">{{ _date.d }}</span>
    </div>
    <div class="stal-time">
      <span class="stal-time-hour">{{ _date.h }}</span>
      <span class="colon">&nbsp;:&nbsp;</span>
      <span class="stal-time-min">{{ _date.m }}</span>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import mixins      from 'utils/mixins';

export default {
  mixins: [mixins],

  // TODO : remaining n'est pas encore géré
  // TODO : ago n'est pas encore géré

  data () { return {
    _date: { y:0, k:0, d:0, h:0, m:0 }, // year ~ kyriel ~ day   hour : minute
    showDate: true,
    showTime: true,
    showAgo: false
  };},

  props: {
    date: { type: String, default(){ return '' } },
    remaining: { type: Boolean, default(){ return false; } },
    format: {
      type: String,
      default(){ return 'datetime' },
      validator(value){
        return -1 != ['datetime', 'date', 'time', 'ago'].indexOf(value);
      }
    }
  },

  methods: {
    parseDate(){
      let matches = this.date.match(/^(\d+)~(\d+)~(\d+) (\d+):(\d+)$/);
      this._date = {y: matches[1], k: matches[2], d: matches[3], h: matches[4], m: matches[5]};
    },
    parseFormat(){
      if(this.format == 'date' || this.format == 'datetime') this.showDate = true; else this.showDate = false;
      if(this.format == 'time' || this.format == 'datetime') this.showTime = true; else this.showTime = false;
    }
  },

  created(){
    if(this.date !== '') this.parseDate();
    // else this._date // TODO : get current date from vuex store
    this.parseFormat();
  }
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  .stal-datetime{
    @include flex-row-center; display: inline-flex;
    &>div{
      @include flex-row;
    }
    &>div+div{
      margin-left: 0.8em;
    }

    .tilde{
      transform: scaleY(2);
    }
  }
</style>