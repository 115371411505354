<!--
App
Main component of the application.
-->
<template>
  <router-view class="root"></router-view>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  data () { return {
    msg: 'Hello world DEUX!'
  }}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'normalize';  // Do it once here.
  @import 'ui';         // Do it once here. Contains ui classes.
  @import 'utils';  // Import this in all components
</style>