<!--
HeaderVisitor
Header destiné aux utilisateurs non-connectés
-->
<template>
  <header-main class="header-visitor">
    <template slot="navlinks">
      <li class="home-li"><router-link :to="{name:'homepage'}" class="home-link" exact>
        <icon name="stalnom" class="logo"/>
        <span class="site-title">Stalnom's Traces</span>
      </router-link></li>
      <div class="home-link-margin"></div>
      <header-links :links="links"></header-links>
    </template>
  </header-main>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>

import mixins      from 'utils/mixins';

import Icon from 'components/widgets/icon.vue';

import HeaderMain  from 'components/sections/header/header-main.vue';
import HeaderLinks from 'components/sections/header/header-links.vue';

export default {
  mixins: [mixins],

  components: {
    HeaderMain,
    HeaderLinks,
    Icon
  },

  data () { return {
    links: [
      {text: 'Actualités', route: {name: 'actupage'}},
      {text: 'Forum', route: {name: 'forumpage'}},
      {text: 'Inscription', route: {name: 'homepage'}}
    ]
  };}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  @import './links.scss';
  .header-visitor{
    .home-link{
      @include header-link-no-arrow;
    }
    .logo{
      height: $header-items-height; width: auto;
      padding-right: $header-items-padding;
    }
    .site-title{
      font-size: 1.6rem;
      padding-top: 0.4rem; // fix font metrics
      @include typo-crimson-semibold;
    }

    .home-link-margin{
      width: 2rem;
      background: $color-stal-red;
    }
  }
</style>