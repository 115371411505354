<!--
ForumPage
Utilise le default-layout
-->
<template>
  <default-layout class="actupage">
      <p>Ceci est la page du forum. Qu'est-ce qu'on se marre !</p>
  </default-layout>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>

import DefaultLayout from 'components/layouts/default-layout.vue';

export default {
  components: {
    'default-layout': DefaultLayout
  },

  data () { return {
    
  };}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  //.forumpage{}
</style>