<!--
DefaultLayout
Layout des pages principales : header + contenu + footer (pas de sidebar)
-->
<template>
  <div class="default-layout">
    <header-visitor></header-visitor>
    <div class="content">
        <slot>&lt;&lt;Aucun contenu&gt;&gt;</slot>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>

import HeaderVisitor from 'components/sections/header/header-visitor.vue';

export default {
  components: {
    HeaderVisitor
  },

  data () { return {
    
  };}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  .default-layout{ // is also stylised in header-main component (adds padding)
    @include flex-col;
    min-height: 100vh;
    background: $color-background;
  }
</style>