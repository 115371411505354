<!--
HomePage
Utilise le default-layout
-->
<template>
  <default-layout class="homepage">
      <p>Ceci est la page d'accueil.</p>
  </default-layout>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>

import DefaultLayout from 'components/layouts/default-layout.vue';

export default {
  components: {
    'default-layout': DefaultLayout
  },

  data () { return {
    
  };}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  //.homepage{}
</style>