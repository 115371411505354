'use strict';

import user from './user';

const store_data = {
    strict: process.env.NODE_ENV !== 'production',

    modules: {
        user
    },

    state(){ return{

    };},

    getters: {
        
    },

    mutations: {
        
    },
    
    actions: {
        
    }

};

export default store_data;