<!--
Icon
Component servant à afficher une icon chargée depuis le sprite des icones
Props :
- name : le nom de l'icone
- color : (facultatif) la couleur CSS de l'icone. Pour utiliser les couleurs SASS (variables, il faut utiliser une classe de votre côté)
- size : (facultatif) la taille CSS (avec unité) de l'icone'
-->
<template>
  <svg class="icon" :class="'icon-'+name" width="32" height="32" :style="{color:color, width:size, height:size}">
    <use :xlink:href="makeUrl('/icons.svg#icon-'+name)"></use>
  </svg>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import mixins      from 'utils/mixins';

export default {
  mixins: [mixins],
  data () { return {};},
  props: {
    name: { type: String },
    color: { type: String },
    size: { type: String }
  }
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  .icon{

  }
</style>