'use strict';

import VueRouter from 'vue-router';

import HomePage  from 'components/pages/homepage.vue';
import ActuPage  from 'components/pages/actupage.vue';
import ForumPage from 'components/pages/forumpage.vue';

const router = new VueRouter({
    mode: 'history',
    base: '', // Base URL. Empty string for hosted version. '/vonefrontend' for wamp. (Or the name of www subdirectory if different.)
    routes: [
    // Modèle
    //    {
    //        name: 'home',
    //        path: '/',
    //        component: Component
    //    },
        {
            name: 'homepage',
            path: '/',
            component: HomePage
        },
        {
            name: 'actupage',
            path: '/actu/',
            component: ActuPage
        },
        {
            name: 'forumpage',
            path: '/forum/',
            component: ForumPage
        }
    
    ]
});

function makeUrl(url){
    return router.base + url;
}

export {makeUrl, router};