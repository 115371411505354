<!--
HeaderTimer
Placement du timer dans le header. Futurement, il utilisera le widget stalnomTImer
-->
<template>
  <li class="header-timer-wrapper">
    <router-link :to="{name:'homepage'}" title="Date et heure stalnomiennes actuelles">
      <icon name="clock" size="1.2em"/>
      <stal-datetime date="4~22~15 22:34" class="stalnom-time"/>
    </router-link>
  </li>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import Icon from 'components/widgets/icon.vue';
import StalDatetime from 'components/widgets/stal-datetime.vue';

export default {
  components: {
    Icon,
    StalDatetime
  },
  data () { return {};}
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  @import './links.scss';
  li.header-timer-wrapper{
    background: $color-stal-red;
    @include flex-row;

    a{
      @include flex-row-center;
      padding-right: ((1rem - 0.72rem) / 2) + 0.5rem; // same as the header-arrow
      .stalnom-time{
        @include header-font-fix;
        align-self: stretch;
        .tilde{ @include header-font-fix; }
      }
      .icon{
        padding-right: $header-items-padding;
        @include flex-row-center;
      }
    }
  }
</style>