'use strict';

const userStore = {
    namespaced: true,

    state(){return{
        logged: false,  // means visitor
    };},

    getters: {
        
    },

    mutations: {
        
    },
    
    actions: {
        
    }
};

export default userStore;