<!--
HeaderLinks
Component servant à afficher dynamiquement des liens dans le menu
-->
<template>
  <ul class="menu-links">
    <li v-for="item in links" class="menu-li">
      <router-link :to="item.route" :key="item.text" :class="item.class">
      {{ item.text }}
      </router-link>
    </li>
  </ul>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  data () { return {};},
  props: {
    links: { type: Array, default(){return [];} } // links are like [{route, text, <class>}] (class optional)
  }
}
</script>
<!-------------------------------------------------------------------------------------------------->
<style lang="scss">
  @import 'utils';  // Import this in all components
  @import './links.scss';
  .menu-links>li a{ // class used in header-links
    @include header-font-fix;
    @include header-link-arrow;
  }
</style>